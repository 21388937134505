/**
Custom Options List
 */

.optionsList {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: auto;
}

.optionsList__item {
    padding: 0 20px;
    font-size: 14px;
    font-family: "Google Sans", sans-serif;
    transition: padding .3s ease-in-out;
    border-bottom: 1px solid #202323;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
}

.optionsList__noResults {
    padding: 18px 20px;
}

.optionsList__item::after {
    height: 10px;
    width: 5px;
    color: #919899;
    background: url('src/components/optionsList/chevron.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    opacity: 0.5;
}

.optionsList__item:hover {
    padding-left: 28px;
}

.optionsList__item button {
    text-decoration: none;
    color: white;
    display: inline-block;
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;
    font-size: 14px;
    font-family: "Google Sans", sans-serif;
    cursor: pointer;
    height: 100%;
}
