/* Different features that has been hidden */

/* TYPOGRAPHY */

/* Plus Button in Typoghraphy tab */
#library-tab-text button,

/* Adaptive text colors toggle */
#library-pane-text [class*="textPane__SmartColorToggle"],

/* Match fonts from Story */
#library-pane-text [class*="textSetsPane__TextSetsToggle"],

/*Media Pane search input*/
#library-pane-media [class*="styles__SearchInputContainer"],
[id^="provider-tabpanel"] h2,

/* PAGE TEMPLATES */

/* Match fonts from Story */
[class*="defaultTemplates__TemplatesToggle"],

/* Help section */
[class*="secondaryMenu__MenuItems"] [class*="helpCenter__Wrapper"],

/* Checklist section */
[class*="secondaryMenu__MenuItems"] [class*="checklist__Wrapper"],

/* Space before keyboard shortcuts */
[class*="secondaryMenu__MenuItems"] [class*="secondaryMenu__Space"],


/* OPTIONS WHEN VIDEO/IMAGE SELECTED */

/* Add link */
[class*="menu__MenuWrapper"] .add-link,


/* MAIN PANEL */

/* Save draft */
.save-draft,


/* DESIGN PANEL */

/* Design tab (Background or element selected) */
/* Page Background Audio, Accessibility, Link, Page Attachment */
.sidebar-design-page-attachment,
.sidebar-design-background-audio,
.sidebar-design-link,
.sidebar-design-accessibility,
.sidebar-saved-colors,
.sidebar-saved-styles,

/* Document tab */
[class*="tabview__Tabs"] #document,

/* Text element - right click options for saved styles and colors */
.add-color-to-“saved-colors”,
.add-style-to-“saved-styles”

{
    display: none !important;
}

#library-pane-text [class*="textPane__SmartColorToggle"] + [class*="section__Container"] {
    margin-top: 0;
}

#library-pane-pageTemplates [class*="pageTemplatesPane__DropDownWrapper"] {
    margin-top: 15px;
}

/* MAIN PANEL - Hide Safety Margins*/
[class*="framesLayer__"] {
    border: none !important;
}