
/**
Templates list
 */

.templatesListByFolder {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.templatesListByFolder__noResults {
    padding: 18px 20px;
}

