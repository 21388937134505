@import 'hidden-elements.css';
@import 'google-sans-font.css';
@import 'noto-emoji-font.css';

:root {
  --icon-button-size: 32px;
}

body {
  margin: 0;
  font-family: 'Noto Color Emoji', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
