.load__icon {
  animation: linear load 2s infinite;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}
