.studio-container {
  background-color: #131516;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  color: #444;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 13px;
  line-height: 1.4em;
  min-width: 600px;
  scrollbar-width: thin;
  scrollbar-color: 'transparent';
}

.studio-container * {
  scrollbar-color: 'transparent';
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  background: rgba('#FFF', 0.08);
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color:#393D3F;
  border: 2px solid transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.hide-if-no-js {
  display: none;
}

.js .hide-if-no-js {
  display: block;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  box-shadow: none;
  outline: 0;
}
